<template>
  <section>
    <div v-if="editable" class="d-flex justify-content-end">
      <button class="btn btn-success btn-sm mb-2" @click="pusht">
        <i class="mdi mdi-plus-circle-multiple-outline"></i>
      </button>
    </div>
    <b-carousel
      id="carousel1"
      controls
      indicators
      background="#ababab"
      :interval="4000"
      v-model="slide1"
    >
      <b-carousel-slide v-for="(item, itemID) in items" :key="itemID">
        <!-- button edit image -->
        <div v-if="editable">
          <add-image
            :modal="'addImageCarouselMeb' + itemID + sectionTitle"
            @saveImage="saveImage(item, $event['image'])"
          />
        </div>
        <!-- <a class="editimg" href="#" v-b-modal="'photo-add-modal'+itemID">
          <i class="fa fa-photo"></i>
        </a> -->
        <!--end button edit image -->
        <img
          slot="img"
          v-if="item['carousel-image']"
          :src="item['carousel-image']"
          alt="slide image"
        />
        <img
          slot="img"
          v-else
          :src="require('../../assets/images/import/add-picture.png')"
          alt="slide image"
        />

        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <div>
              <!-- editable texte -->
              <string-text class="black"
                v-for="(description, descriptionId) in item.text"
                :key="descriptionId"
                :items="description"
                :edit="false"
                :editable="editable"
                :description-index="item.text.indexOf(description)"
                @remove-text="item.text.splice(item.text.indexOf($event), 1)"
                @push-text="
                  item.text.splice($event['index'], 0, $event['text'])
                "
                @typographie-text="textTypographie(description, $event)"
              >
              </string-text>
            </div>
          </div>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </section>
</template>

<script>
import stringText from "./string-text.vue";
import slide_element from "./json/carousel-meb-slide.json";
import addImage from "../functions/add-image.vue";

export default {
  name: "carousel-meb",
  components: {
    stringText,
    addImage,
  },
  data() {
    return {
      slide1: 0,
      picture_link: "",
      slidesel: slide_element,
      items: [],
    };
  },
  methods: {
    pusht() {
      let event = this.slidesel;
      this.$emit("push-carousel-meb-new-slide", event);
    },
    saveImage(item, image) {
      item["carousel-image"] = image;
    },
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
  },
  props: {
    entitytype: String,
    title: String,
    description: String,
    button: String,
    link: String,
    slides: Array,
    sectionTitle: String,
    buttonText: String,
    buttonAlignment: String,
    buttonLink: String,
    editable: Boolean,
  },
  mounted() {
    this.entitytype ? this.getEntitytype() : (this.items = this.slides);
    // console.log(this.slides);
  },
};
</script>

<style scoped>
.carousel .carousel-inner .carousel-item img {
  width: 50%;
}
.carousel-item {
  /* background: #ffff !important; */
  background: #fff !important;
}

.black {
  color: black
}

h3,
p {
  color: black;
}
.editimg {
  margin-right: 40px;
}
.addslide {
  text-align: center;
}
</style>